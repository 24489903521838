import { request } from'./Omni';
import { Config } from '../comun';

const AdminServiciosAPI = {
  getRegistro: async (parametro) => {
    const _url = `${Config.apiGateway}/${parametro}`;
    //console.log(_url);

    return request(_url, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
  },
  postRegistro: async (tabla,parametros) => {
    const _url = `${Config.apiGateway}/${tabla}`;
    //console.log(parametros);
    return request(_url, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(parametros),
    });
  },
  
}

export default AdminServiciosAPI;