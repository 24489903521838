const isLocal = window.location.host==='localhost:4001' || window.location.host==='localhost'
const hostITD =  isLocal?'192.168.200.10/acuerdos.link/index.php':window.location.host;
let datecookie = new Date();
datecookie.setTime(datecookie.getTime() + (1 * 24 * 60 * 60 * 1000));
const Config = {
    dirroot: window.location.protocol+'//'+hostITD,
    apiGateway: 'https://ln8k2ceqgk.execute-api.us-east-2.amazonaws.com',
    parametrosCookie: isLocal?{path: '/', expires: datecookie}:{path: '/', expires: datecookie, domain: '.acuerdos.link'},
    urlEscritorio: isLocal?'http://localhost:4002':'https://escritorio.acuerdos.link'
}

export default Config;