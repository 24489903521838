import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Entrar from './publico/Entrar';

function App() {
  return (
    <div className="container">
        <Entrar />
    </div>
    
  );
}

export default App;
