/**
 * An async fetch with error catch
 * @param url
 * @param data
 * @returns {Promise.<*>}
 */
export const request = async (url, data = {}) => {
    try {
      const response = await fetch(url, data);
      return response;
    } catch (err) {
      console.log(err);
      return { error: err };
    }
};

export const getLabelfromValue = (array,key) => {
  const objIndex = array.findIndex((obj => obj.value === key));
  return array[objIndex].label;
}

export const formatCurrency = (value) => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const formatDate = (date) => {  
  if (!(date instanceof Date)) {
    throw new Error('Invalid "date" argument. You must pass a date instance')
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}