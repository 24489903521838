import React, { useState, useRef } from 'react'  
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useCookies } from 'react-cookie';
import { Messages } from 'primereact/messages';
import AdminServiciosAPI from '../servicios/AdminServicios';
import './login.css'
import { Config } from '../comun';

function Entrar() {
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [, setCookie] = useCookies(['user']);
    const mensaje = useRef(null);
    const [cargando, setCargando] = useState(false);
    const [codigo, setCodigo] = useState('')

    const _entrar = async () => {
        setCargando(true);
        const response = await AdminServiciosAPI.postRegistro('usuarios',{usuario});
        if(!response.ok){
            const errormsj = await response.json();
            mensaje.current.show({
                severity: 'info',
                sticky: true,
                detail: errormsj
            });
        }else{
            const datosUsuario = await response.json();
            setCodigo(datosUsuario)
        }
        setCargando(false);
    }

    const _loguear = () => {
        if(clave === codigo.codigo.toString()){
            //console.log('usuario',codigo.usuario)
            delete codigo.usuario.datos.idusuario
            localStorage.setItem('ACUSUARIO', JSON.stringify(codigo.usuario.datos));
            setCookie('ACCOLOR', codigo.cookie, Config.parametrosCookie);
            window.location.href=Config.urlEscritorio
        }else{
            mensaje.current.show({
                severity: 'warn',
                sticky: true,
                detail: 'Lo sentimos por favor ingresa el código correcto'
            });
        }
    }

    return (
        <div>
            <Messages ref={mensaje} />
            <div className="form-login">
                <h3>Acceso Restringido</h3>
                {codigo==='' && <div>
                    <div>
                        <InputText type='email' className="form-control form-control-lg" value={usuario} onChange={(e) => setUsuario(e.target.value)} placeholder='Usuario' />
                    </div>
                    
                    <div className="form-group text-center mt-3">
                        <Button type="button" loading={cargando} className="btn btn-lg btn-primary w-100" label="Entrar" onClick={() =>_entrar()} />
                    </div>
                </div>}
                {codigo!=='' && <div>
                    <div>
                        <InputText type="number" className="form-control form-control-lg" value={clave} onChange={(e) => setClave(e.target.value)} placeholder='Código' />
                    </div>
                    
                    <div className="form-group text-center mt-3">
                        <Button type="button" loading={cargando} className="btn btn-lg btn-primary w-100" label="Validar" onClick={() =>_loguear()} />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Entrar